/* eslint-env browser */
import merge from 'deepmerge';
import { ObjectFromEntries } from './Hacks';

function throwOnExtraFields(config, key, allowedFields) {
  const extraFieldsForKey = Object.getOwnPropertyNames(config.fields[key]).filter(
    property => allowedFields.indexOf(property) === -1,
  );

  if (extraFieldsForKey.length > 0) {
    throw new Error(
      `You provided too many fields in ${key}. Unexpected fields: ${extraFieldsForKey.join(',')}`,
    );
  }
}

function validateInlineCCNumberField(config, key) {
  throwOnExtraFields(config, key, [
    'display',
    'selector',
    'title',
    'placeholder',
    'enableCardBrandPreviews',
  ]);

  if (typeof config.fields.ccnumber.enableCardBrandPreviews !== 'boolean') {
    throw new Error('fields.ccnumber.enableCardBrandPreviews must be a boolean.');
  }
}

function validateInlineField(config, key) {
  throwOnExtraFields(config, key, ['display', 'selector', 'title', 'placeholder']);
}

function validateApplePayField(config, key) {
  const acceptableFieldProperties = [
    'selector',
    'shippingMethods',
    'shippingType',
    'requiredBillingContactFields',
    'requiredShippingContactFields',
    'contactFields',
    'contactFieldsMappedTo',
    'lineItems',
    'totalLabel',
    'totalType',
    'style',
    'type',
  ];

  const extraFieldsForKey = Object.getOwnPropertyNames(config.fields[key]).filter(
    property => acceptableFieldProperties.indexOf(property) === -1,
  );

  if (
    typeof config.fields.applePay.shippingMethods !== 'undefined' &&
    !(config.fields.applePay.shippingMethods instanceof Array)
  ) {
    throw new Error('shippingMethods must be an array.');
  }

  if (config.fields.applePay.shippingMethods instanceof Array) {
    for (let i = 0; i < config.fields.applePay.shippingMethods.length; i += 1) {
      if (typeof config.fields.applePay.shippingMethods[i] !== 'object') {
        throw new Error('All items in applePay.shippingMethods must be objects');
      }

      if (typeof config.fields.applePay.shippingMethods[i].label !== 'string') {
        throw new Error(`applePay.shippingMethods[${i}].label must be a string`);
      }

      if (typeof config.fields.applePay.shippingMethods[i].amount !== 'string') {
        throw new Error(`applePay.shippingMethods[${i}].amount must be a string`);
      }
      if (typeof config.fields.applePay.shippingMethods[i].detail !== 'string') {
        throw new Error(`applePay.shippingMethods[${i}].detail must be a string`);
      }
      if (typeof config.fields.applePay.shippingMethods[i].identifier !== 'string') {
        throw new Error(
          `applePay.shippingMethods[${i}].identifier must be a string. Example: expressShipping`,
        );
      }
    }
  }

  const shippingTypes = ['shipping', 'delivery', 'storePickup', 'servicePickup'];
  if (
    typeof config.fields.applePay.shippingType !== 'string' ||
    !shippingTypes.includes(config.fields.applePay.shippingType)
  ) {
    throw new Error(
      `applePay.shippingType must be one of the following: ${shippingTypes.join(',')}`,
    );
  }

  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaycontactfield
  const billingContactFields = ['name', 'postalAddress'];
  if (config.fields.applePay.requiredBillingContactFields instanceof Array) {
    for (let i = 0; i < config.fields.applePay.requiredBillingContactFields.length; i += 1) {
      if (!billingContactFields.includes(config.fields.applePay.requiredBillingContactFields[i])) {
        throw new Error(
          `requiredBillingContactFields contains invalid option: ` +
            `${config.fields.applePay.requiredBillingContactFields[i]}. Accepted options are:` +
            `${billingContactFields.join(', ')}`,
        );
      }
    }
  }

  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaycontactfield
  const shippingContactFields = ['name', 'postalAddress'];
  if (config.fields.applePay.requiredShippingContactFields instanceof Array) {
    for (let i = 0; i < config.fields.applePay.requiredShippingContactFields.length; i += 1) {
      if (
        !shippingContactFields.includes(config.fields.applePay.requiredShippingContactFields[i])
      ) {
        throw new Error(
          `requiredShippingContactFields contains invalid option: ` +
            `${config.fields.applePay.requiredShippingContactFields[i]}. Accepted options are:` +
            `${shippingContactFields.join(', ')}`,
        );
      }
    }
  }

  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaycontactfield
  const contactFields = ['phone', 'email'];
  if (config.fields.applePay.contactFields instanceof Array) {
    for (let i = 0; i < config.fields.applePay.contactFields.length; i += 1) {
      if (!contactFields.includes(config.fields.applePay.contactFields[i])) {
        throw new Error(
          `contactFields contains invalid option: ` +
            `${config.fields.applePay.contactFields[i]}. Accepted options are:` +
            `${contactFields.join(', ')}`,
        );
      }
    }
  }

  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaycontactfield
  const contactFieldsMappedTo = ['billing', 'shipping'];
  if (typeof config.fields.applePay.contactFieldsMappedTo === 'string') {
    if (!contactFieldsMappedTo.includes(config.fields.applePay.contactFieldsMappedTo)) {
      throw new Error(
        `contactFieldsMappedTo contains invalid option: ` +
          `${config.fields.applePay.contactFieldsMappedTo}. Accepted options are:` +
          `${contactFieldsMappedTo.join(', ')}`,
      );
    }
  }

  if (config.fields.applePay.lineItems instanceof Array) {
    for (let i = 0; i < config.fields.applePay.lineItems.length; i += 1) {
      if (typeof config.fields.applePay.lineItems[i].label !== 'string') {
        throw new Error(`applePay.lineItems[${i}].label must be a string.`);
      }

      if (typeof config.fields.applePay.lineItems[i].amount !== 'string') {
        throw new Error(`applePay.lineItems[${i}].amount must be a string.`);
      }
    }
  }

  if (typeof config.fields.applePay.totalLabel !== 'string') {
    throw new Error('applePay.totalLabel must be a string');
  }

  const validTotalTypes = ['pending', 'final'];
  if (
    config.fields.applePay.totalType &&
    !validTotalTypes.includes(config.fields.applePay.totalType)
  ) {
    throw new Error(
      `applePay.totalType contains invalid option: ` +
        `${config.fields.applePay.totalType}. Accepted options are:` +
        `${validTotalTypes.join(', ')}`,
    );
  }

  const acceptableTypes = [
    'buy',
    'donate',
    'plain',
    'set-up',
    'book',
    'check-out',
    'subscribe',
    'add-money',
    'contribute',
    'order',
    'reload',
    'rent',
    'support',
    'tip',
    'top-up',
  ];
  if (
    typeof config.fields.applePay.type !== 'string' ||
    !acceptableTypes.includes(config.fields.applePay.type)
  ) {
    throw new Error(
      `Invalid option - '${config.fields.applePay.type}' ` +
        `provided for applePay.type. Acceptable options: ${acceptableTypes.join(', ')}`,
    );
  }

  if (typeof config.fields.applePay.style !== 'object') {
    throw new Error('applePay.style must be an object');
  }

  const acceptableStyleProperties = ['button-style', 'height', 'border-radius'];
  const extraFieldsForStyle = Object.getOwnPropertyNames(config.fields.applePay.style).filter(
    property => acceptableStyleProperties.indexOf(property) === -1,
  );
  if (extraFieldsForStyle.length > 0) {
    throw new Error(
      `You provided too many fields in applePay.style. Unexpected fields: ${extraFieldsForStyle.join(
        ',',
      )}`,
    );
  }

  if (typeof config.fields.applePay.style['border-radius'] !== 'string') {
    throw new Error(
      `Invalid option - '${config.fields.applePay.style['border-radius']}' provided ` +
        `for applePay.style.borderRadius. borderRadius must be a string.`,
    );
  }

  if (typeof config.fields.applePay.style.height !== 'string') {
    throw new Error(
      `Invalid option - '${config.fields.applePay.style.height}' provided ` +
        `for applePay.style.height. Height must be a string.`,
    );
  }

  const acceptableStyles = ['black', 'white', 'white-outline'];
  if (
    typeof config.fields.applePay.style['button-style'] !== 'string' ||
    !acceptableStyles.includes(config.fields.applePay.style['button-style'])
  ) {
    throw new Error(
      `Invalid option - '${config.fields.applePay.style['button-style']}' provided ` +
        `for applePay.style.buttonStyle. buttonStyle must be one of the following: ${acceptableStyles.join(
          ', ',
        )}`,
    );
  }

  if (extraFieldsForKey.length > 0) {
    throw new Error(
      `You provided too many fields in ${key}. Unexpected fields: ${extraFieldsForKey.join(',')}`,
    );
  }
}

function validateGooglePayField(config, key) {
  const acceptableFieldProperties = [
    'selector',
    'shippingAddressRequired',
    'shippingAddressParameters',
    'billingAddressRequired',
    'billingAddressParameters',
    'emailRequired',
    'buttonType',
    'buttonColor',
    'buttonLocale',
    'totalPriceStatus',
  ];

  const extraFieldsForKey = Object.getOwnPropertyNames(config.fields[key]).filter(
    property => acceptableFieldProperties.indexOf(property) === -1,
  );

  if (
    typeof config.fields.googlePay.shippingAddressRequired !== 'undefined' &&
    typeof config.fields.googlePay.shippingAddressRequired !== 'boolean'
  ) {
    throw new Error('shippingAddressRequired must be a boolean.');
  }

  if (config.fields.googlePay.shippingAddressParameters instanceof Object) {
    if (
      !(config.fields.googlePay.shippingAddressParameters.allowedCountryCodes instanceof Array) &&
      config.fields.googlePay.shippingAddressParameters.allowedCountryCodes !== undefined
    ) {
      throw new Error(
        'googlePay.shippingAddressParameters.allowedCountryCodes must be an ' +
          'array of 2 letter country codes.',
      );
    }

    if (
      typeof config.fields.googlePay.shippingAddressParameters.phoneNumberRequired !== 'boolean'
    ) {
      throw new Error('googlePay.shippingAddressParameters.phoneNumberRequired must be a boolean.');
    }
  }

  if (
    typeof config.fields.googlePay.billingAddressRequired !== 'undefined' &&
    typeof config.fields.googlePay.billingAddressRequired !== 'boolean'
  ) {
    throw new Error('shippingAddressRequired must be a boolean.');
  }

  if (config.fields.googlePay.billingAddressParameters instanceof Object) {
    if (typeof config.fields.googlePay.billingAddressParameters.format !== 'string') {
      throw new Error('googlePay.billingAddressParameters.format must be a string.');
    }

    if (!['MIN', 'FULL'].includes(config.fields.googlePay.billingAddressParameters.format)) {
      throw new Error('googlePay.billingAddressParameters.format must be MIN or FULL.');
    }

    if (typeof config.fields.googlePay.billingAddressParameters.phoneNumberRequired !== 'boolean') {
      throw new Error('googlePay.billingAddressParameters.phoneNumberRequired must be a boolean.');
    }
  }

  if (
    typeof config.fields.googlePay.emailRequired !== 'undefined' &&
    typeof config.fields.googlePay.emailRequired !== 'boolean'
  ) {
    throw new Error('emailRequired must be a boolean.');
  }

  const buttonLocaleOptions = [
    'en',
    'ar',
    'bg',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'es',
    'et',
    'fi',
    'fr',
    'hr',
    'id',
    'it',
    'ja',
    'ko',
    'ms',
    'nl',
    'no',
    'pl',
    'pt',
    'ru',
    'sk',
    'sl',
    'sr',
    'sv',
    'th',
    'tr',
    'uk',
    'zh',
  ];
  if (
    typeof config.fields.googlePay.buttonLocale !== 'undefined' &&
    !buttonLocaleOptions.includes(config.fields.googlePay.buttonLocale)
  ) {
    throw new Error(
      `buttonLocale must be one of the following: '${buttonLocaleOptions.join("', '")}'`,
    );
  }

  const buttonColorOptions = ['default', 'black', 'white'];
  if (
    typeof config.fields.googlePay.buttonColor !== 'undefined' &&
    !buttonColorOptions.includes(config.fields.googlePay.buttonColor)
  ) {
    throw new Error(
      `buttonColor must be one of the following: '${buttonColorOptions.join("', '")}'`,
    );
  }

  const buttonTypeOptions = [
    'short', // Backwards Compatibility - maps to "plain"
    'long', // Backwards Compatibility - maps to "buy"
    'book',
    'buy',
    'checkout',
    'donate',
    'order',
    'pay',
    'plain',
    'subscribe',
  ];
  if (
    typeof config.fields.googlePay.buttonType !== 'undefined' &&
    !buttonTypeOptions.includes(config.fields.googlePay.buttonType)
  ) {
    throw new Error(`buttonType must be one of the following: '${buttonTypeOptions.join("', '")}'`);
  }

  const totalPriceStatusOptions = ['FINAL', 'ESTIMATED'];
  if (
    typeof config.fields.googlePay.totalPriceStatus !== 'undefined' &&
    !totalPriceStatusOptions.includes(config.fields.googlePay.totalPriceStatus)
  ) {
    throw new Error(
      `totalPriceStatus must be one of the following: '${totalPriceStatusOptions.join("', '")}'`,
    );
  }

  if (extraFieldsForKey.length > 0) {
    throw new Error(
      `You provided too many fields in ${key}. Unexpected fields: ${extraFieldsForKey.join(',')}`,
    );
  }
}

/**
 * Private method to clean up data passed in odd formats.
 * @param config
 * @returns {boolean}
 */
function normalize(config) {
  const obj = { ...config };
  if (typeof obj.styleSniffer !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.styleSniffer = obj.styleSniffer.toLowerCase() !== 'false';
  }

  if (typeof obj.collectShippingInfo !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.collectShippingInfo = obj.collectShippingInfo.toLowerCase() !== 'false';
  }

  if (typeof obj.collectBillingInfo !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.collectBillingInfo = obj.collectBillingInfo.toLowerCase() !== 'false';
  }

  if (typeof obj.validationCallback === 'string' && obj.validationCallback !== '') {
    // eslint-disable-next-line no-eval
    obj.validationCallback = eval(obj.validationCallback);
  }

  if (typeof obj.fieldsAvailableCallback === 'string' && obj.fieldsAvailableCallback !== '') {
    // eslint-disable-next-line no-eval
    obj.fieldsAvailableCallback = eval(obj.fieldsAvailableCallback);
  }

  if (typeof obj.timeoutCallback === 'string' && obj.timeoutCallback !== '') {
    // eslint-disable-next-line no-eval
    obj.timeoutCallback = eval(obj.timeoutCallback);
  }

  // Once the merging is done, we can reconstitute the JSON if needed
  if (typeof obj.customCss === 'string' && obj.customCss !== '') {
    obj.customCss = JSON.parse(obj.customCss);
  }

  if (typeof obj.invalidCss === 'string' && obj.invalidCss !== '') {
    obj.invalidCss = JSON.parse(obj.invalidCss);
  }

  if (typeof obj.focusCss === 'string' && obj.focusCss !== '') {
    obj.focusCss = JSON.parse(obj.focusCss);
  }

  if (typeof obj.placeholderCss === 'string' && obj.placeholderCss !== '') {
    obj.placeholderCss = JSON.parse(obj.placeholderCss);
  }

  if (typeof obj.validCss === 'string' && obj.validCss !== '') {
    obj.validCss = JSON.parse(obj.validCss);
  }

  if (
    typeof obj.fields.ccnumber.enableCardBrandPreviews !== 'undefined' &&
    typeof obj.fields.ccnumber.enableCardBrandPreviews !== 'boolean'
  ) {
    // Smash down a string value to a boolean, default to true
    obj.fields.ccnumber.enableCardBrandPreviews =
      obj.fields.ccnumber.enableCardBrandPreviews.toLowerCase() !== 'false';
  }

  if (
    typeof obj.fields.googlePay.shippingAddressRequired !== 'undefined' &&
    typeof obj.fields.googlePay.shippingAddressRequired !== 'boolean'
  ) {
    // Smash down a string value to a boolean, default to true
    obj.fields.googlePay.shippingAddressRequired =
      obj.fields.googlePay.shippingAddressRequired.toLowerCase() !== 'false';
  }

  if (typeof obj.fields.googlePay.shippingAddressParameters.phoneNumberRequired !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.fields.googlePay.shippingAddressParameters.phoneNumberRequired =
      obj.fields.googlePay.shippingAddressParameters.phoneNumberRequired.toLowerCase() !== 'false';
  }

  if (typeof obj.fields.googlePay.shippingAddressParameters.allowedCountryCodes === 'string') {
    const { allowedCountryCodes } = obj.fields.googlePay.shippingAddressParameters;
    obj.fields.googlePay.shippingAddressParameters.allowedCountryCodes = allowedCountryCodes.split(
      ',',
    );
  }

  if (typeof obj.fields.googlePay.billingAddressRequired !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.fields.googlePay.billingAddressRequired =
      obj.fields.googlePay.billingAddressRequired.toLowerCase() !== 'false';
  }

  if (typeof obj.fields.googlePay.billingAddressParameters.phoneNumberRequired !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.fields.googlePay.billingAddressParameters.phoneNumberRequired =
      obj.fields.googlePay.billingAddressParameters.phoneNumberRequired.toLowerCase() !== 'false';
  }

  if (typeof obj.fields.googlePay.emailRequired !== 'boolean') {
    // Smash down a string value to a boolean, default to true
    obj.fields.googlePay.emailRequired =
      obj.fields.googlePay.emailRequired.toLowerCase() !== 'false';
  }

  if (
    typeof obj.fields.googlePay.totalPriceStatus !== 'undefined' &&
    typeof obj.fields.googlePay.totalPriceStatus === 'string'
  ) {
    // Convert to upper case to match Google format
    obj.fields.googlePay.totalPriceStatus = obj.fields.googlePay.totalPriceStatus.toUpperCase();
  }

  if (
    typeof obj.fields.applePay.shippingMethods === 'string' &&
    obj.fields.applePay.shippingMethods !== ''
  ) {
    obj.fields.applePay.shippingMethods = JSON.parse(obj.fields.applePay.shippingMethods);
  }

  if (
    typeof obj.fields.applePay.requiredBillingContactFields === 'string' &&
    obj.fields.applePay.requiredBillingContactFields !== ''
  ) {
    obj.fields.applePay.requiredBillingContactFields = JSON.parse(
      obj.fields.applePay.requiredBillingContactFields,
    );
  }

  if (
    typeof obj.fields.applePay.requiredShippingContactFields === 'string' &&
    obj.fields.applePay.requiredShippingContactFields !== ''
  ) {
    obj.fields.applePay.requiredShippingContactFields = JSON.parse(
      obj.fields.applePay.requiredShippingContactFields,
    );
  }

  if (
    typeof obj.fields.applePay.contactFields === 'string' &&
    obj.fields.applePay.contactFields !== ''
  ) {
    obj.fields.applePay.contactFields = JSON.parse(obj.fields.applePay.contactFields);
  }

  if (typeof obj.fields.applePay.lineItems === 'string' && obj.fields.applePay.lineItems !== '') {
    obj.fields.applePay.lineItems = JSON.parse(obj.fields.applePay.lineItems);
  }

  if (
    typeof obj.fields.applePay.style === 'object' &&
    typeof obj.fields.applePay.style['button-style'] === 'undefined'
  ) {
    obj.fields.applePay.style['button-style'] = 'black';
  }

  if (
    typeof obj.fields.applePay.style === 'object' &&
    typeof obj.fields.applePay.style['border-radius'] === 'undefined'
  ) {
    obj.fields.applePay.style['border-radius'] = '4px';
  }

  if (
    typeof obj.fields.applePay.style === 'object' &&
    typeof obj.fields.applePay.style.height === 'undefined'
  ) {
    obj.fields.applePay.style.height = '30px';
  }

  return obj;
}

/**
 * Private method to validate a config object's properties
 *
 * @param config
 * @returns {boolean}
 */
function validate(config) {
  const acceptableProperties = [
    'callback',
    'paymentSelector',
    'paymentType',
    'theme',
    'primaryColor',
    'secondaryColor',
    'buttonText',
    'fields',
    'tokenizationKey',
    'cartCorrelationId',
    'variant',
    'styleSniffer',
    'snifferClass',
    'customCss',
    'invalidCss',
    'validCss',
    'placeholderCss',
    'token',
    'validationCallback',
    'timeoutDuration',
    'timeoutCallback',
    'focusCss',
    'googleFont',
    'fieldsAvailableCallback',
    'instructionText',
    'country',
    'price',
    'currency',
    'collectShippingInfo',
    'collectBillingInfo',
    'nscript', // allowing this property will let CollectJS load in implementations using NextJS v12
  ];

  const extraFields = Object.getOwnPropertyNames(config).filter(
    property => acceptableProperties.indexOf(property) === -1,
  );

  if (extraFields.length > 0) {
    throw new Error(`You provided too many fields. Unexpected fields for ${extraFields.join(',')}`);
  }

  Object.keys(config.fields).forEach(key => {
    switch (key) {
      case 'applePay':
        validateApplePayField(config, key);
        break;
      case 'googlePay':
        validateGooglePayField(config, key);
        break;
      case 'ccnumber':
        validateInlineCCNumberField(config, key);
        break;
      case 'cvv':
      case 'ccexp':
      case 'checkaccount':
      case 'checkaba':
      case 'checkname':
      case 'checktransit':
      case 'checkinstitution':
      default:
        validateInlineField(config, key);
        break;
    }
  });

  if (config.variant === 'inline' && config.instructionText !== 'Please enter payment info') {
    throw new Error('You cannot specify instruction text with inline fields');
  }

  if (typeof config.callback !== 'function') {
    throw new Error('config.callback must be a function');
  }

  if (typeof config.instructionText !== 'string') {
    throw new Error('config.instructionText must be a string');
  }

  if (typeof config.tokenizationKey !== 'string' || config.tokenizationKey.length === 0) {
    throw new Error(
      'A tokenization key must be provided by including a data-tokenization-key attribute',
    );
  } else if (
    !config.tokenizationKey.match(/^.{6}-.{6}-.{6}-.{6}$/g) &&
    !config.tokenizationKey.match(/^checkout_public_.{32}$/g)
  ) {
    throw new Error('Invalid tokenization key format');
  }
  const validThemes = ['bootstrap', 'material'];
  if (validThemes.indexOf(config.theme) === -1) {
    throw new Error('Invalid theme provided');
  }

  const validTypes = ['cc', 'ck'];
  if (validTypes.indexOf(config.paymentType) === -1) {
    throw new Error("Payment type must be either 'cc' or 'ck'");
  }

  const validCvv = ['show', 'required', 'hide'];
  if (validCvv.indexOf(config.fields.cvv.display) === -1) {
    throw new Error("Cvv must be either 'show', 'required', or 'hide'");
  }

  const validVariants = ['inline', 'lightbox'];
  if (validVariants.indexOf(config.variant) === -1) {
    throw new Error("Variant must be either 'inline' or 'lightbox'");
  }

  return true;
}

class Config {
  /**
   * @param obj
   * @returns {Config}
   */
  constructor(obj) {
    this.callback = response => {
      // Technically, an implementer could provide anything for the event.
      if (response.initiatedBy instanceof Event) {
        let form;
        if (response.initiatedBy.target instanceof HTMLFormElement) {
          form = response.initiatedBy.target;
        } else {
          form = response.initiatedBy.target.parentNode.closest('form');
        }
        if (form instanceof HTMLFormElement) {
          const tokenInput = document.createElement('input');
          tokenInput.setAttribute('name', 'payment_token');
          tokenInput.setAttribute('value', response.token);
          tokenInput.setAttribute('type', 'hidden');
          form.appendChild(tokenInput);
          form.submit();
        }
      }
    };
    this.tokenizationKey = '';
    this.cartCorrelationId = '';
    this.paymentSelector = '#payButton';
    this.theme = 'bootstrap';
    this.primaryColor = '#1CD18E';
    this.secondaryColor = '#FFFFFF';
    this.buttonText = 'Submit Payment';
    this.paymentType = 'cc';
    this.variant = 'lightbox';
    this.styleSniffer = true;
    this.snifferClass = '';
    this.validationCallback = false;
    this.customCss = '';
    this.timeoutDuration = 0;
    this.timeoutCallback = false;
    this.fieldsAvailableCallback = false;
    this.invalidCss = '';
    this.focusCss = '';
    this.validCss = '';
    this.placeholderCss = '';
    this.googleFont = '';
    this.instructionText = 'Please enter payment info';
    this.country = undefined;
    this.price = undefined;
    this.currency = undefined;
    this.collectShippingInfo = false;
    this.collectBillingInfo = false;
    // We have to preserve backwards compatibility in case they are using a string for cvv
    const cvvDisplay = obj.fieldCvv || obj.fieldCvvDisplay || 'required'; // show|hide|required
    // The names below may look odd but that is how they will come in via the .dataset call.
    this.fields = {
      cvv: {
        display: cvvDisplay,
        selector: obj.fieldCvvSelector || '#cvv',
        placeholder: obj.fieldCvvPlaceholder || '',
        title: obj.fieldCvvTitle || '',
      },
      ccnumber: {
        selector: obj.fieldCcnumberSelector || '#ccnumber',
        placeholder: obj.fieldCcnumberPlaceholder || '',
        title: obj.fieldCcnumberTitle || '',
        enableCardBrandPreviews: obj.fieldCcnumberEnableCardBrandPreviews || false,
      },
      ccexp: {
        selector: obj.fieldCcexpSelector || '#ccexp',
        placeholder: obj.fieldCcexpPlaceholder || '',
        title: obj.fieldCcexpTitle || '',
      },
      checkaccount: {
        selector: obj.fieldCheckaccountSelector || '#checkaccount',
        placeholder: obj.fieldCheckaccountPlaceholder || '',
        title: obj.fieldCheckaccountTitle || '',
      },
      checkaba: {
        selector: obj.fieldCheckabaSelector || '#checkaba',
        placeholder: obj.fieldCheckabaPlaceholder || '',
        title: obj.fieldCheckabaTitle || '',
      },
      checkname: {
        selector: obj.fieldChecknameSelector || '#checkname',
        placeholder: obj.fieldChecknamePlaceholder || '',
        title: obj.fieldChecknameTitle || '',
      },
      checktransit: {
        selector: obj.fieldChecktransitSelector || '#checktransit',
        placeholder: obj.fieldChecktransitPlaceholder || '',
        title: obj.fieldChecktransitTitle || '',
      },
      checkinstitution: {
        selector: obj.fieldCheckinstitutionSelector || '#checkinstitution',
        placeholder: obj.fieldCheckinstitutionPlaceholder || '',
        title: obj.fieldCheckinstitutionTitle || '',
      },
      googlePay: {
        selector: obj.fieldGooglePaySelector || '#googlepaybutton',
        shippingAddressRequired: obj.fieldGooglePayShippingAddressRequired || false,
        shippingAddressParameters: {
          allowedCountryCodes:
            obj.fieldGooglePayShippingAddressParametersAllowedCountryCodes || undefined,
          phoneNumberRequired:
            obj.fieldGooglePayShippingAddressParametersPhoneNumberRequired || false,
        },
        billingAddressRequired: obj.fieldGooglePayBillingAddressRequired || false,
        billingAddressParameters: {
          format: obj.fieldGooglePayBillingAddressParametersFormat || 'MIN',
          phoneNumberRequired:
            obj.fieldGooglePayBillingAddressParametersPhoneNumberRequired || false,
        },
        totalPriceStatus: obj.fieldGooglePayTotalPriceStatus || 'FINAL',
        emailRequired: obj.fieldGooglePayEmailRequired || false,
        buttonType: obj.fieldGooglePayButtonType || 'buy',
        buttonColor: obj.fieldGooglePayButtonColor || 'default',
        buttonLocale: obj.fieldGooglePayButtonLocale || 'en',
      },
      applePay: {
        selector: obj.fieldApplePaySelector || '#applepaybutton',
        shippingMethods: obj.fieldApplePayShippingMethods || '[]',
        shippingType: obj.fieldApplePayShippingType || 'shipping',
        requiredBillingContactFields: obj.fieldApplePayRequiredBillingContactFields || '[]',
        requiredShippingContactFields: obj.fieldApplePayRequiredShippingContactFields || '[]',
        contactFields: obj.fieldApplePayContactFields || '[]',
        contactFieldsMappedTo: obj.fieldApplePayContactFieldsMappedTo || 'billing',
        lineItems: obj.fieldApplePayLineItems || '[]',
        style: obj.fieldApplePayStyle || {
          'button-style': obj.fieldApplePayStyleButtonStyle,
          height: obj.fieldApplePayStyleHeight,
          'border-radius': obj.fieldApplePayStyleBorderRadius,
        },
        type: obj.fieldApplePayType || 'buy',
        totalLabel: obj.fieldApplePayTotalLabel || 'Total',
        totalType: obj.fieldApplePayTotalType || 'final',
      },
    };
    // We have to delete anything in field since it wont merge right
    const configObj = { ...obj };
    delete configObj.fieldCvv;
    delete configObj.fieldCvvDisplay;
    delete configObj.fieldCvvSelector;
    delete configObj.fieldCcnumberSelector;
    delete configObj.fieldCcexpSelector;
    delete configObj.fieldCheckaccountSelector;
    delete configObj.fieldCheckabaSelector;
    delete configObj.fieldChecktransitSelector;
    delete configObj.fieldCheckinstitutionSelector;
    delete configObj.fieldChecknameSelector;
    delete configObj.fieldCvvPlaceholder;
    delete configObj.fieldCvvTitle;
    delete configObj.fieldCcnumberPlaceholder;
    delete configObj.fieldCcnumberEnableCardBrandPreviews;
    delete configObj.fieldCcnumberTitle;
    delete configObj.fieldCcexpPlaceholder;
    delete configObj.fieldCcexpTitle;
    delete configObj.fieldCheckaccountPlaceholder;
    delete configObj.fieldCheckaccountTitle;
    delete configObj.fieldCheckabaPlaceholder;
    delete configObj.fieldCheckabaTitle;
    delete configObj.fieldChecktransitPlaceholder;
    delete configObj.fieldChecktransitTitle;
    delete configObj.fieldCheckinstitutionPlaceholder;
    delete configObj.fieldCheckinstitutionTitle;
    delete configObj.fieldChecknamePlaceholder;
    delete configObj.fieldChecknameTitle;
    delete configObj.fieldGooglePaySelector;
    delete configObj.fieldGooglePayShippingAddressRequired;
    delete configObj.fieldGooglePayShippingAddressParametersAllowedCountryCodes;
    delete configObj.fieldGooglePayShippingAddressParametersPhoneNumberRequired;
    delete configObj.fieldGooglePayBillingAddressRequired;
    delete configObj.fieldGooglePayBillingAddressParametersFormat;
    delete configObj.fieldGooglePayBillingAddressParametersPhoneNumberRequired;
    delete configObj.fieldGooglePayEmailRequired;
    delete configObj.fieldGooglePayButtonType;
    delete configObj.fieldGooglePayButtonLocale;
    delete configObj.fieldGooglePayButtonColor;
    delete configObj.fieldGooglePayTotalPriceStatus;
    delete configObj.fieldApplePaySelector;
    delete configObj.fieldApplePayShippingMethods;
    delete configObj.fieldApplePayShippingType;
    delete configObj.fieldApplePayRequiredBillingContactFields;
    delete configObj.fieldApplePayRequiredShippingContactFields;
    delete configObj.fieldApplePayContactFields;
    delete configObj.fieldApplePayContactFieldsMappedTo;
    delete configObj.fieldApplePayLineItems;
    delete configObj.fieldApplePayTotalLabel;
    delete configObj.fieldApplePayTotalType;
    delete configObj.fieldApplePayStyle;
    delete configObj.fieldApplePayType;
    delete configObj.fieldApplePayStyleButtonStyle;
    delete configObj.fieldApplePayStyleHeight;
    delete configObj.fieldApplePayStyleBorderRadius;

    let newConfig = merge(this, configObj);
    newConfig = normalize(newConfig);
    Object.assign(this, newConfig);
    validate(this);
    return this;
  }

  update(obj) {
    const configObj = { ...obj };
    if (configObj.fields) {
      if (typeof obj.fields.cvv === 'string') {
        configObj.fields.cvv = {
          display: obj.fields.cvv,
        };
      }
    }

    let newConfig = merge(this, configObj);
    newConfig = normalize(newConfig);
    Object.assign(this, newConfig);
    validate(this);
    return this;
  }

  lightboxParams(token) {
    let queryString = '';
    queryString += `tokenizationKey=${encodeURIComponent(this.tokenizationKey)}&`;
    queryString += `primaryColor=${encodeURIComponent(this.primaryColor)}&`;
    queryString += `secondaryColor=${encodeURIComponent(this.secondaryColor)}&`;
    queryString += `theme=${encodeURIComponent(this.theme)}&`;
    queryString += `buttonText=${encodeURIComponent(this.buttonText)}&`;
    queryString += `paymentType=${encodeURIComponent(this.paymentType)}&`;
    queryString += `cvv=${encodeURIComponent(this.fields.cvv.display)}&`;
    queryString += `instructionText=${encodeURIComponent(this.instructionText)}&`;
    queryString += `token=${encodeURIComponent(token)}&`;
    queryString += `currency=${encodeURIComponent(this.currency)}`;
    return queryString;
  }

  inlineParams(elementType, token) {
    let queryString = '';
    queryString += `tokenizationKey=${encodeURIComponent(this.tokenizationKey)}&`;
    queryString += `cartCorrelationId=${encodeURIComponent(this.cartCorrelationId)}&`;
    queryString += `token=${encodeURIComponent(token)}&`;
    queryString += `elementId=${encodeURIComponent(elementType)}&`;
    queryString += `title=${encodeURIComponent(this.fields[elementType].title)}&`;
    queryString += `placeholder=${encodeURIComponent(this.fields[elementType].placeholder)}`;

    if (elementType === 'ccnumber') {
      queryString += `&enableCardBrandPreviews=${encodeURIComponent(
        this.fields[elementType].enableCardBrandPreviews,
      )}`;
    }

    if (elementType === 'cvv') {
      queryString += `&cvvDisplay=${encodeURIComponent(this.fields.cvv.display)}`;
    }
    return queryString;
  }

  getInlineFields() {
    const entries = Object.entries(this.fields);

    const inlineFields = entries.filter(element => {
      return Config.INLINE_FIELDS.includes(element[0]);
    });

    return ObjectFromEntries(inlineFields);
  }
}

Config.INLINE_FIELDS = [
  'cvv',
  'ccnumber',
  'ccexp',
  'checkaccount',
  'checkaba',
  'checkname',
  'checktransit',
  'checkinstitution',
];

export default Config;
